<template>
	<el-dialog class="dialog" title="忘记密码" :visible.sync="visiable" width="20%">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :rules="rules" :model="formData" label-width="130px">
				<el-row>
					<el-col :span="24">
						<el-form-item label="手机号码" prop="mobile">
							<el-input v-model="formData.mobile"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item class="sms" label="短信验证码" style="width: 100%;">
							<el-input v-model="formData.smsCode" style="flex:1">
							</el-input>
							<el-button @click="getSms()" style="width: 110px;">{{smsText}}</el-button>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="新密码">
							<el-input v-model="formData.pwd"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="24">
						<el-form-item label="再次输入新密码" prop="pwd2">
							<el-input v-model="formData.pwd2"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">提交</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	var timers = null;
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				formData: {},
				loading: false,
				smsText: "获取验证码",
				times: 60,
				rules: {
					mobile: {
						validator: (rule, value, callback) => {
							const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
							if (!value) {
								return callback(new Error('电话号码不能为空'))
							}
							setTimeout(() => {
								if (!Number.isInteger(+value)) {
									callback(new Error('请输入数字'))
								} else {
									if (phoneReg.test(value)) {
										callback()
									} else {
										callback(new Error('电话号码格式不正确'))
									}
								}
							}, 100)
						},
						trigger: 'blur'
					},
					pwd2: [{
						validator: (rule, value, callback) => {
							const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
							if (!value) {
								return callback(new Error('此项必填'))
							}
							if (value != this.formData.pwd) {
								return callback(new Error('两次输入的密码不一致'))
							}else{
								callback();
							}
						},
						trigger: 'blur'
					}],
				}
			};
		},
		props: {},
		watch: {
			isShow(val) {
				if (val) {
					this.open();
				}
			}
		},
		methods: {
			submit() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.$postData('/user-api/auth/forgetPwd/update', this.formData).then(res => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg)
								this.close();
							}else{
								this.loading = false;
								this.$message.error(res.msg)
							}
						})
					}
				})
			},
			//打开弹窗
			open() {
				this.formData = {};
			},
			getSms() {
				let _this = this;
				if (this.times != 60) {
					this.$message('请稍后再试')
					return
				}
				this.$post('/user-api/auth/forgetPwd/mobileCode/get', {
					mobile: this.formData.mobile
				}).then(data => {
					if (data.code != 1000) {
						this.$message.error(data.msg)
						return
					}
					this.$message({
						message: "发送成功",
						type: "success",
					});
					timers = setInterval(function() {
						_this.smsText = _this.times + 's后重试'
						if (_this.times == 0) {
							_this.times = 60;
							clearInterval(timers);
							_this.smsText = '获取验证码'
						} else {
							_this.times--
						}
					}, 1000) //每隔1000毫秒弹一次
				})
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>

<style lang='scss' scoped>
	.dialog {
		min-width: 400px;

		::v-deep .el-dialog__header {
			background-color: #F8F8F8;
			height: 56px;
			padding: 17px 24px;
		}

		::v-deep .el-dialog__body {
			padding: 0;

			.dlalog-body {
				position: relative;

				.el-form {
					padding: 30px 20px;
				}

				.dialog-footer {
					padding: 15px 20px;
					border-top: 1px solid #EEEEEE;
					text-align: right
				}
			}
		}

		::v-deep .el-transfer-panel {
			width: 300px;
		}

		::v-deep .el-select,
		::v-deep .el-cascader {
			display: block;
		}

		.sms {
			::v-deep.el-form-item__content {
				display: flex;
			}
		}

	}
</style>
