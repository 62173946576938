<template>
	<div class="login">
		<div class="logo">
			<img class="mt-30 ml-30" src="../../assets/osImg/loginPageLogo.png" alt=""
				style="width: 271px;height: 63px;" />
		</div>
		<div class="contain pos-center flex">
			<div class="left flex flex-col jc-center ai-center">
				<img src="../../assets/osImg/load_paint.png" alt="" style="width:791px;height: 346px;" />
				<div style="font-size: 16px;font-weight: 400;color: #595959;margin-top: 70px;">
					从采集、监控、告警、分析等全面打通 可视化构建统一监控大屏展示
				</div>
				<!-- 	<div style="font-size: 12px;font-weight: 400;color: #8C8C8C;">
					Comprehensively open up visualization from acquisition, monitoring, alarm, analysis, etc. to build a unified monitoring large-screen display
				</div> -->
			</div>

			<div class="right flex-1 pos-rel">
				<div class="ic" @click="qrCodeOpen()">
					<el-tooltip class="item" content="扫码登录更快捷" placement="right">
						<img class="fr" src="../../assets/osImg/QRCode.png" alt="" />
					</el-tooltip>
				</div>

				<div class="box pos-xcenter">
					<div class="title">圣名智能监测平台</div>

					<div class="num" @click="loginType=!loginType">{{!loginType?'切换账号登录':"切换手机登录"}}</div>

					<el-form :model="ruleForm" status-icon size="mini" ref="ruleForm" label-width="100px"
						class="loginForm">
						<el-form-item>
							<el-input type="text" v-if="loginType" placeholder="请输入账号" v-model="ruleForm.loginName">
							</el-input>
							<el-input type="text" v-if="!loginType" placeholder="请输入手机号" v-model="ruleForm.mobile"
								onkeyup="value=value.replace(/[^\d]/g,'')">
							</el-input>
						</el-form-item>
						<el-form-item v-if="loginType">
							<el-input type="password" placeholder="请输入密码" v-model="ruleForm.pwd" autocomplete="off">
							</el-input>
						</el-form-item>
						<el-form-item v-if="!loginType">
							<el-input style="width: 60%;" type="text" placeholder="请输入验证码" v-model="ruleForm.smsCode"
								autocomplete="off">
							</el-input>
							<el-button style="margin-left: 10px;" type="primary" @click="getSms()" :loading="false">
								{{smsText}}
							</el-button>
						</el-form-item>
						<el-form-item>
							<el-button class="submit" type="primary" @click="submitForm('ruleForm')">提交</el-button>
						</el-form-item>
						<el-form-item>
							<div class="flex jc-between">
								<span style="cursor: pointer;" @click="forgetFn">忘记密码</span>
								<!-- <span>注册</span> -->
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<!--    二维码弹框-->
		<el-dialog title="请使用长材运维平台APP扫码登录" :visible.sync="qrCodeDialogVisible" width="30%" @opened="qrCodeCreate"
			:before-close="qrCodeHandleClose">
			<div class="qrCodeBox">
				<div id="qrCode" ref="qrCode"></div>
				<div class="qrCodeBoxSuss" v-if="qrCodeZhe">
					<!-- <img class="qrCodeBoxSuss-img" src="https://img.alicdn.com/imgextra/i3/O1CN01LRwWNO1XolOsD646D_!!6000000002971-55-tps-120-84.svg" > -->
				</div>
			</div>
			<div class="qrCodeBoxtip" v-if="qrCodeZhe">
				<div class="qrCodeBoxSussTitle">二维码已过期</div>
				<div class="qrCodeBoxSussTitle botton" @click="resQr()">刷新二维码</div>
			</div>
			<span slot="footer" class="dialog-footer">
			</span>
		</el-dialog>

		<!--    忘记密码弹框-->
		<!-- <el-dialog title="忘记密码" :visible.sync="forgetDialog" width="30%" :before-close="forgetDialogFn">
			<div style="width: 80%;margin: 0 auto;">
				<div class="flex jc-between ai-center dialog-item">
					<div class="flex dia-title">
						<span>手机号码 </span>
						<span>*</span>
					</div>
					<el-input size="small" class="dia-input" type="number" placeholder="请输入手机号码"></el-input>
				</div>
				<div class="flex jc-between ai-center dialog-item">
					<div class="flex dia-title">
						<span>手机验证码 </span>
						<span>*</span>
					</div>
					<div class="flex dia-input">
						<el-input size="small" style="width: 70%;" type="number" placeholder="请输入手机验证码"></el-input>
						<el-button size="small" style="margin-left: 10px;" type="primary" @click="getSms()">
							{{smsText}}
						</el-button>
					</div>
				</div>
				<div class="flex jc-between ai-center dialog-item">
					<div class="flex dia-title">
						<span>新密码</span>
						<span>*</span>
					</div>
					<el-input size="small" class="dia-input" type="text" placeholder="请输入新密码"></el-input>
				</div>
				<div class="flex jc-between ai-center dialog-item">
					<div class="flex dia-title">
						<span>再次输入新密码 </span>
						<span>*</span>
					</div>
					<el-input size="small" class="dia-input" type="text" placeholder="请再次输入新密码"></el-input>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="forgetDialog = false">取 消</el-button>
				<el-button type="primary">提交</el-button>
			</span>
		</el-dialog> -->
		<div class="foot">
			<p>Copyright © smheee.com 圣名远扬, All Rights Reserved.</p>
			<p>Email:shengming_tech@163.com Tel: 020-31802914</p>
		</div>
		<forget-password :isShow.sync="showFPWDialog" :dialogObj="FPWObj"></forget-password>
	</div>
</template>

<script>
	var timers;
	import caches from "@cache/cache.js"
	import QRCode from 'qrcodejs2'
	import forgetPassword from './forgetPassword.vue'
	export default {
		components: {
			forgetPassword
		},
		data() {
			return {
				times: 60,
				smsText: '获取验证码',
				timer: '',
				ruleForm: {
					pwd: "",
					loginName: "",
					smsCode: '',
					mobile: ""
				},
				qrCodeDialogVisible: false, //二维码弹窗
				qrCode: '',
				loginUUid: '',
				qrCodeZhe: false,
				loginType: true, //true为账号登录，false手机号登录
				showFPWDialog: false,
				FPWObj: {}
			};
		},
		created() {
			this.enterSearch();
		},
		methods: {
			getSms() {
				let _this = this;
				let rule = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
				if (!rule.test(this.ruleForm.mobile)) {
					this.$message('手机号码不正确')
					return
				}
				if (this.times != 60) {
					this.$message('请稍后再试')
					return
				}
				this.$post('/user-api/auth/mobileCode/get?mobile=' + this.ruleForm.mobile).then(data => {
					if (data.code != 1000) {
						this.$message.error(data.msg)
						return
					}
					this.$message({
						message: "发送成功",
						type: "success",
					});
					_this.sliderVerifyFLag = false;
					timers = setInterval(function() {
						_this.smsText = _this.times + 's后重试'
						if (_this.times == 0) {
							_this.times = 60;
							clearInterval(timers);
							_this.smsText = '获取验证码'
						} else {
							_this.times--
						}
					}, 1000) //每隔1000毫秒弹一次
				})
			},
			geneRole(userType) {
				if (userType == 1) {
					this.role = "platform";
					document.title = '棒线材设备智能运维系统(平台端)'
				} else if (userType == 2) {
					this.role = "company";
				} else if (userType == 3) {
					this.role = "supplier";
				} else if (userType == 4) {
					this.role = "expert";
				}
				caches.role.set(this.role);
			},
			submitForm(formName) {
				let loading = this.openLoading();
				let obj, url;
				this.$refs[formName].validate((data) => {
					if (data) {
						if (this.loginType) {
							obj = {
								pwd: this.ruleForm.pwd,
								loginName: this.ruleForm.loginName
							}
							url = '/user-api/auth/userName/login'
						} else {
							obj = {
								smsCode: this.ruleForm.smsCode,
								mobile: this.ruleForm.mobile
							}
							url = '/user-api/auth/mobileCode/login'
						}
						//请求的用法
						this.$post(url, obj).then((data) => {
								loading.close();
								//判断是否是1000
								if (data.code != 1000) {
									this.$message.error(data.msg);
									return;
								}
								//未完善资料，跳转去完善资料
								if (data.data.status == 2 || data.data.status == 4) {
									if (data.data.userType == 3) {
										//供应商
										this.$router.push({
											name: "registerGYS",
											query: {
												active: 1,
												id: data.data.id
											}
										});
									} else if (data.data.userType == 4) {
										//专家
										this.$router.push({
											path: "/registerZJ",
											query: {
												active: 1,
												id: data.data.id
											}
										});
									}
								} else if (data.data.status == 3) {
									if (data.data.userType == 3) {
										//供应商
										this.$router.push({
											name: "registerGYS",
											query: {
												active: 2,
												id: data.data.id
											}
										});
									} else if (data.data.userType == 4) {
										//专家
										this.$router.push({
											path: "/registerZJ",
											query: {
												active: 2,
												id: data.data.id
											}
										});
									}
								} else {
									this.$store.commit('setUniqueID',data.data.id)
									caches.token.set(data.data.token);
									this.$get("user-api/user-base/userInfo", {}).then((res) => {
										this.$message({
											message: "登录成功",
											type: "success",
										});
										caches.user.set(res.data);
										this.geneRole(res.data.userType);
										var location = caches.loginLocation.get() ? caches
											.loginLocation.get() : "home";
										this.$router.push({
											name: location
										});
									});
								}
							})
							.catch(() => {
								loading.close();
								this.$message.error("请求失败");
							});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			//点击打开忘记密码
			forgetFn() {
				this.showFPWDialog = true;
			},
			//关闭修改弹框
			forgetDialogFn() {
				this.showFPWDialog = false;
			},
			//关闭弹框清除二维码
			qrCodeHandleClose() {
				this.qrCodeDialogVisible = false;
				this.qrCode = '';
				document.getElementById('qrCode').innerHTML = '';
				clearInterval(this.timer);
			},
			//创建二维码
			qrcode(url) {
				this.qrCode = new QRCode('qrCode', {
					text: url,
					width: 200,
					height: 200
				})
			},
			qrCodeCreate() {},
			resQr() {
				this.qrCodeHandleClose()
				this.qrCodeOpen()
			},
			//二维码显示
			qrCodeOpen(val) {
				let loading = this.openLoading();
				this.$get('/user-api/auth/qr-code/get').then(data => {
					loading.close();
					if (data.code != 1000) {
						this.$message.error(data.msg);
					}
					this.loginUUid = data.data.uuid;
					this.qrCodeZhe = false;
					this.$nextTick(() => {
						this.qrcode('ccznwwpt' + data.data.uuid);
					})
					this.qrCodeDialogVisible = true;
					this.timer = setInterval(this.checkLogin, 1000);
				})
			},
			checkLogin() {
				this.$get('/user-api/auth/qr-code/check?uuid=' + this.loginUUid).then(data => {
					if (data.code != 1000) {
						this.$message.error(data.msg);
						clearInterval(this.timer);
						// this.qrCodeHandleClose()
						// this.qrCodeOpen()
						return
					}
					if (data.data.isExpired == 1) {
						this.$message.error("二维码已过期");
						clearInterval(this.timer);
						this.qrCodeZhe = true;
						// this.qrCodeHandleClose()
						// this.qrCodeOpen()
						return
					}
					if (data.data.token) {
						this.$message({
							message: "登录成功",
							type: "success",
						});
						this.qrCodeHandleClose()
						//这里写后续代码
						//登录成功设置token
						caches.token.set(data.data.token);
						// this.$router.push({
						//   path: "/diagnosis",
						// });
						this.$router.back()
					}
				})
			},
			//回车登录
			enterSearch() {
				document.onkeydown = e => {
					//13表示回车键，baseURI是当前页面的地址，为了更严谨，也可以加别的，可以打印e看一下
					if (e.target.baseURI.match(/login/) && e.keyCode === 13) {
						//回车后执行搜索方法
						this.submitForm('ruleForm');
					}
				}
			}

		},
	};
</script>

<style lang="scss" scoped>
	.login {
		width: 100vw;
		height: 100vh;
		background-image: url("../../assets/osImg/bg.png");

		.loginForm {
			::v-deep .el-form-item__content {
				margin-left: 0 !important;
			}

			::v-deep .el-input__inner {
				background-color: #e8e8e8;
			}
		}

		.contain {
			width: 1283px;
			height: 740px;
			background-color: #fff;

			.left {
				width: 871px;
				height: 100%;
				box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);

			}

			.right {
				.ic {
					height: 80px;
					margin: 15px 15px 50px 0;

					img {
						cursor: pointer;
					}
				}

				.box {
					width: 263px;

					.title {
						width: 260px;
						height: 45px;
						font-size: 30px;
						font-weight: 600;
						color: #4a99f6;
						line-height: 45px;
						margin-bottom: 90px;
					}

					.num {
						font-size: 16px;
						color: #4a99f6;
						margin-bottom: 12px;
						cursor: pointer;
					}

					.submit {
						width: 263px;
						height: 40px;
						background: #4a99f6;
						border-radius: 4px;
						margin-top: 98px;
					}
				}
			}
		}

		.qrCodeBox {
			display: flex;
			flex-direction: column;
			width: 100%;
			align-items: center;
			justify-content: center;
			position: relative;
		}

		.qrCodeBoxSuss {
			position: absolute;
			width: 250px;
			height: 250px;
			background-color: #FFFFFF;
			opacity: 0.5;
		}

		.qrCodeBoxSussTitle {
			width: 200px;
			line-height: 40px;
			text-align: center;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
		}

		.botton {
			margin-top: 50px;
			border-radius: 20px;
			color: #409EFF;
			border: 1px solid #409EFF;
		}

		.qrCodeBoxtip {
			display: flex;
			flex-direction: column;
			width: 100%;
			align-items: center;
			justify-content: center;
			position: relative;
		}

		.qrCodeBoxSuss-img {
			width: 120px;
			margin: 0px auto;
		}

		.dialog-item {
			width: 100%;
		}

		.foot {
			min-width: 526px;
			height: 40px;
			position: absolute;
			font-size: 12px;
			left: 50%;
			transform: translateX(-50%);
			bottom: 20px;
			text-align: center;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
		}

		.dia-title {
			width: 150px;
			margin-right: 40px;
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			line-height: 58px;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
		}

		.dia-title>span:last-child {
			color: #D00606;
		}

		.dia-input {
			flex: 1;
		}
	}
</style>
